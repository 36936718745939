
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "cooperative-members-list",
  components: {},
  data() {
    return {
      members: [
        {
          avatar: "",
          name: "",
          email: "",
          phoneNumber: "",
          gender: "",
          policyCount: 0,
          createdOn: "",
          publicId: "",
        },
      ],
      avatarUrl: "",
    };
  },
  setup() {
    const {
      reinitialization,
      useReusableNavigation,
      displayDatatable,
    } = ResusableFunctions();

    onMounted(() => {
      setCurrentPageBreadcrumbs("Members List", ["Cooperative", "Members"]);
      displayDatatable(["basicExample"]);
    });

    return {
      useReusableNavigation,
      reinitialization,
    };
  },
  created() {
    this.avatarUrl = variables.AVATAR_ROUTE;
    this.getMembersList();

    //Set page title
    document.title = "Cooperative: Members List | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    getMembersList() {
      //Fetch list of client
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.COOPERATIVE_MEMBERS_ROUTE)
          .then(({ data }) => {
            //Assign data to props
            this.members = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
    imageUrlAlt(event) {
      event.target.src = "/media/avatars/blank.png";
    },
  },
});
